import React, { useState } from 'react';
import { Stack, ImageList, ImageListItem, IconButton, Box, TextField } from '@mui/material';
import Iconify from 'src/components/iconify';
import { resizeImage, uploadImageFormData } from 'src/services/image.service';

export default function RHFImageUpload({
  initialImage = [],
  fileName,
  folderName,
  multipleFiles = false,
  displayImages = false,
  maxFiles = Infinity,
  filesRequired = 0,
  name,
  setValue,
  width,
  height,
  label,
  maxHeight,
  uploadBox = false,
}) {
  const [image, setImage] = useState(() => {
    if (initialImage === null || initialImage === undefined) {
      return [];
    }
    return Array.isArray(initialImage) ? [...initialImage] : [initialImage];
  });
  const [pendingFiles, setPendingFiles] = useState([]);

  const handleImageUpload = async (files) => {
    if (files.length > 0) {
      try {
        const newFiles = Array.from(files);

        if (!multipleFiles && newFiles.length > 1) {
          alert('You can only upload one file at a time.');
          return;
        }

        if (newFiles.length < filesRequired) {
          alert(`Please upload ${filesRequired} images.`);
          return;
        }

        if (newFiles.length > maxFiles) {
          alert(`You can upload a maximum of ${maxFiles} images.`);
          return;
        }

        // Process all files and get their URLs
        const uploadPromises = newFiles.map(async (file, index) => {
          try {
            const resizedFile = await resizeImage(file, width, height);
            const fileType = resizedFile.type.split('/')[1];
            const extension = fileType === 'jpeg' || fileType === 'jpg' ? 'jpeg' : fileType;
            const brandNameWithExtension = `${fileName}_${index + 1}.${extension}`.toLowerCase();

            const formData = new FormData();
            formData.append('image', resizedFile);

            const url = await uploadImageFormData(formData, brandNameWithExtension, folderName);
            return url.url;
          } catch (error) {
            console.error(`Error processing file ${file.name}:`, error);
            return null; // Return null for failed uploads
          }
        });

        const uploadedUrls = await Promise.all(uploadPromises);

        // Filter out any null URLs
        const validUrls = uploadedUrls.filter(url => url !== null);

        if (multipleFiles) {
          const updatedImages = [...image, ...validUrls];
          setImage(updatedImages);
          setValue(name, updatedImages);
        } else {
          setImage(validUrls);
          setValue(name, validUrls);
        }

        setPendingFiles([]);
      } catch (error) {
        console.error('Error uploading images:', error);
      }
    }
  };

  const handleFileChange = (event) => {
    if (event && event.target && event.target.files) {
      const files = event.target.files;
      handleImageUpload(files);
    } else {
      console.warn('No files were selected or event is invalid');
    }
  };

  const handleDeleteImage = (index) => {
    const updatedImages = image.filter((_, i) => i !== index);
    setImage(updatedImages);
    setValue(name, updatedImages);
  };

  const numOfPlaceholders = Math.max(0, maxFiles - image.length);

  return (
    <Stack gap={2}>
      <Stack spacing={2} sx={{ position: 'relative' }}>
        <TextField
          label={label}
          placeholder={label || 'Upload Image'}
          sx={{ position: 'relative' }}
          InputProps={{
            endAdornment: <Iconify icon="tabler:cloud-upload" />,
          }}
        />
        <input
          id="hidden-file-input"
          type="file"
          multiple={multipleFiles}
          onChange={handleFileChange}
          disabled={image.length >= maxFiles}
          style={{
            opacity: 0,
            position: 'absolute',
            width: '100%',
            height: '100%',
            cursor: 'pointer',
          }}
        />
      </Stack>

      {displayImages && (
        <Stack spacing={2}>
          <ImageList cols={2} sx={{ maxHeight: maxHeight || 240 }}>
            {image.map((imgSrc, index) => (
              <ImageListItem key={index}>
                <Box
                  sx={{
                    position: 'relative',
                    minWidth: width || 164,
                    minHeight: height || 164,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #ccc',
                    borderRadius: 2,
                  }}
                >
                  <img
                    srcSet={`${imgSrc}?w=${width || 164}&h=${height || 164}&fit=crop&auto=format&dpr=2 2x`}
                    src={`${imgSrc}?w=${width || 164}&h=${height || 164}&fit=crop&auto=format`}
                    alt={`Uploaded ${index + 1}`}
                    loading="lazy"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '8px',
                    }}
                  />
                  <IconButton
                    onClick={() => handleDeleteImage(index)}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                      },
                    }}
                  >
                    <Iconify icon="tabler:x" />
                  </IconButton>
                </Box>
              </ImageListItem>
            ))}

            {/* Display placeholders for remaining slots */}
            {numOfPlaceholders > 0 &&
              Array.from({ length: numOfPlaceholders }).map((_, index) => (
                <ImageListItem key={`placeholder-${index}`}>
                  <Box
                    sx={{
                      minWidth: width || 172,
                      minHeight: height || 112,
                      height: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px dashed #ccc',
                      borderRadius: 2,
                      backgroundColor: '#f5f5f5',
                      cursor: 'pointer',
                    }}
                  >
                    <Iconify icon="tabler:add" />
                  </Box>
                </ImageListItem>
              ))}
          </ImageList>
        </Stack>
      )}
    </Stack>
  );
}