export const BACKEND_URL = process.env.REACT_APP_HOST_API;

export const urlMap = {
  // auth
  auth: {
    login: `${BACKEND_URL}/v1/auth/login`,
    clinic_login: `${BACKEND_URL}/v1/auth/clinic-login`,
    doctor_login: `${BACKEND_URL}/v1/auth/doctor-login`,
    admin_login: `${BACKEND_URL}/v1/auth/admin-login`,
  },
  // user
  user: {
    details: `${BACKEND_URL}/v1/users/:userId`,
  },
  clinic: {
    list: `${BACKEND_URL}/v1/clinics?page=pageValue&limit=limitValue`,
    create: `${BACKEND_URL}/v1/clinics`,
    users: `${BACKEND_URL}/v1/clinics/:clinicId/get-members?page=pageValue&limit=limitValue`,
    createUser: `${BACKEND_URL}/v1/clinics/:clinicId/add-member`,
    details: `${BACKEND_URL}/v1/clinics/:clinicId`,
    update: `${BACKEND_URL}/v1/clinics/:clinicId`,
    doctors: `${BACKEND_URL}/v1/clinics/:clinicId/doctor`,
    checkDoctor: `${BACKEND_URL}/v1/clinics/:clinicId/doctor/check`,
  },

  staff: {
    list: `${BACKEND_URL}/v1/users/staff?&page=pageValue&limit=limitValue`,
    create: `${BACKEND_URL}/v1/users`,
    updateUserPassword: `${BACKEND_URL}/v1/users/reset-user-password`,
  },
  images: {
    upload: `${BACKEND_URL}/v1/images/upload?fileName=FileName&folderName=FolderName`,
  },
  packages: {
    create: `${BACKEND_URL}/v1/packages`,
    list: `${BACKEND_URL}/v1/packages/clinic/:clinicId`,
    getDetails: `${BACKEND_URL}/v1/packages/:packageId`,
    delete:  `${BACKEND_URL}/v1/packages/:packageId`,
    update: `${BACKEND_URL}/v1/packages/:packageId`,
    publish: `${BACKEND_URL}/v1/packages/:packageId/publish`,
  },
  doctors: {
    getDetails: `${BACKEND_URL}/v1/doctors/:userId`,
    update: `${BACKEND_URL}/v1/doctors/:userId`,
    getAll: `${BACKEND_URL}/v1/doctors`
  },
  procedures: {
    list: `${BACKEND_URL}/v1/procedures?page=pageValue&limit=limitValue`,
    add: `${BACKEND_URL}/v1/procedures`,
    remove: `${BACKEND_URL}/v1/procedures/:procedureId`,
    getDetails: `${BACKEND_URL}/v1/procedures/:procedureId`,
    addContent: `${BACKEND_URL}/v1/procedures/:procedureId/content`,
    edit: `${BACKEND_URL}/v1/procedures/:procedureId`
  }
};
