import React, { useRef, useState, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';

const RHFMarkdownEditor = ({ name, defaultValue = '', placeholder }) => {
  const { control } = useFormContext();
  const editorRef = useRef(null);

  // State to hold the editor's content
  const [content, setContent] = useState(defaultValue);

  console.log(content);

  // Effect to update editor content if defaultValue changes
  useEffect(() => {
    setContent(defaultValue);
  }, [defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange } }) => (
        <Editor
          initialValue={content}
          tinymceScriptSrc="/tinymce/js/tinymce/tinymce.min.js"
          onInit={(_evt, editor) => {
            editorRef.current = editor; // Perform operations on content before setting it
            editor.setContent(content); // Set the content on init
          }}
          init={{
            height: 300,
            menubar: false,
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'preview',
              'help',
              'wordcount',
            ],
            toolbar:
              'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            placeholder,
            setup: (editor) => {
              editor.on('init', () => {
                const initialContent = content; // Set content on init with processing
                editor.setContent(initialContent);
              });
              editor.on('change', () => {
                const newContent = editor.getContent();
                console.log(newContent); // Log the updated content
                setContent(newContent); // Update state
                onChange(newContent); // Update form value (no need to stringify unless you specifically want it)
              });
            },
          }}
        />
      )}
    />
  );
};

export default RHFMarkdownEditor;
