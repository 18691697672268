import { postData, getData, putData, getDataGuest } from 'src/utils/axios';
import { urlMap } from 'src/utils/urls';

export const getClinics = async (pageValue = 1, limitValue = 10) => {
  const url = `${urlMap.clinic.list
    .replace('pageValue', `${pageValue}`)
    .replace('limitValue', `${limitValue}`)}`;
  return getData(url);
};

export const getClinicUsers = async (clinicId, pageValue = 1, limitValue = 10) => {
  const url = `${urlMap.clinic.users.replace(':clinicId', clinicId)}`;
  return getData(url);
};

export const createClinic = async (data) => {
  const url = `${urlMap.clinic.create}`;
  return postData(url, data);
};

export const addClinicUser = async (clinicId, data) => {
  const url = `${urlMap.clinic.createUser.replace(':clinicId', clinicId)}`;
  return postData(url, data);
};

export const getClinicDetails = async (clinicId) => {
  const url = `${urlMap.clinic.details.replace(':clinicId', clinicId)}`;
  return getData(url);
};

export const updateClinicDetails = async (clinicId, data) => {
  const url = `${urlMap.clinic.update.replace(':clinicId', clinicId)}`;
  return putData(url, data);
};

export const getClinicDoctors = async (clinicId) => {
  const url = `${urlMap.clinic.doctors.replace(':clinicId', clinicId)}`;
  return getData(url);
};

export const checkClinicDoctor = async (clinicId, data) => {
  const url = `${urlMap.clinic.checkDoctor.replace(':clinicId', clinicId)}`;
  return postData(url, data);
};

export const getUserDetails = async (userId) => {
  const url = `${urlMap.user.details.replace(':userId', userId)}`;
  return getData(url);
};


export const addClinicDoctor = async (clinicId, doctorDetails) => {
  const url = `${urlMap.clinic.doctors.replace(':clinicId', clinicId)}`;
  return postData(url, doctorDetails);
}