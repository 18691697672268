import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button, Stack } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useLocation } from 'react-router';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  emptyRows,
  useTable,
} from 'src/components/table';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { useAuthContext } from 'src/auth/hooks';
import { getClinicDoctors } from 'src/services/clinic.service';
import ClinicDoctorTableRow from './clinic-doctor-table-row';
import AddNewDoctor from './clinic-doctor-add';

const STAFF_LIST_TABLE_HEAD = [
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'telephone_number', label: 'Phone' },
  { id: 'roles', label: 'Roles' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Actions' },
];

const defaultFilters = {
  brand: '',
};

export default function ClinicDoctorsView() {
  const settings = useSettingsContext();
  const location = useLocation();
  const { user } = useAuthContext();
  const [tableData, setTableData] = useState([]);
  const [totalStaff, setTotalStaff] = useState(0);
  const [filters, setFilters] = useState(defaultFilters);

  const table = useTable({
    defaultRowsPerPage: 10,
    order: 'asc',
  });

  const denseHeight = table.dense ? 56 : 76;
  const notFound = !tableData.length;

  useEffect(() => {
    getClinicDoctors(user.clinic_id)
      .then((staffList) => {
        console.log(staffList);
        if (staffList) {
            console.log('staffList = ', staffList);
          const tempTableData = staffList.map((staff) => ({
            staff_id: staff.id,
            name: `${staff.user_id.first_name} ${staff.user_id.last_name}`,
            telephone_number: staff.user_id.telephone_number,
            email: staff.user_id.email,
            roles: staff.user_id.roles,
            status: staff.user_id.status,
          }));
          console.log(tempTableData);
          setTableData(tempTableData);
          setTotalStaff(tempTableData.length);
        }
      })
      .catch((error) => {
        console.log('error = ', error);
      });
  }, [user]);

  const handleViewRow = (id) => {
    console.log('view row clicked', id);
  };

  const handleChangePage = (event, newPage) => {
    table.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    table.setRowsPerPage(parseInt(event.target.value, 10));
    table.setPage(0); // Reset to the first page when changing rows per page
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb="28px" py="10px">
        <Typography variant="h4"> Doctors List </Typography>
        <AddNewDoctor />
      </Stack>
      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={STAFF_LIST_TABLE_HEAD}
              rowCount={totalStaff}
              onSort={table.onSort}
            />

            <TableBody>
              {tableData.map((row) => (
                <ClinicDoctorTableRow
                  key={row.id}
                  row={row}
                  tablePage={table.page}
                  onViewRow={() => handleViewRow(row.id)}
                />
              ))}
              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
              />
              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={totalStaff} // total number of records
        page={table.page} // current page number
        rowsPerPage={table.rowsPerPage} // number of rows per page
        onPageChange={handleChangePage} // handle page change
        onRowsPerPageChange={handleChangeRowsPerPage} // handle change in rows per page
        dense={table.dense} // handle table density
        onChangeDense={table.onChangeDense} // handle density change
        backIconButtonProps={{
          disabled: table.page === 0, // disable back button if on first page
        }}
        nextIconButtonProps={{
          disabled: table.page >= Math.ceil(totalStaff / table.rowsPerPage) - 1, // disable next button if on last page
        }}
      />
    </Container>
  );
}
