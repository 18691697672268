import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Snackbar, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import RHFImageUpload from 'src/components/hook-form/rhf-image-upload';
import Iconify from 'src/components/iconify';
import { addProcedureContent, editProcedure } from 'src/services/procedures.service';

export default function EditProcedurePopup({
  fieldName,
  fieldContent,
  fieldSubHeading,
  procedureId,
}) {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const form = useForm({
    defaultValues: {
      [fieldName]: fieldContent || '',
    },
  });

  const { register, handleSubmit, setValue, formState } = form;
  const { errors } = formState;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = (data) => {
    if (fieldName === 'content') {
      const finalData = {
        heading: fieldSubHeading,
        description: data.content,
      };
      addProcedureContent(procedureId, { content: finalData })
        .then(() => {
          setSnackbarMessage('Procedure content updated successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          handleClose();
        })
        .catch(() => {
          setSnackbarMessage('Failed to update procedure content');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
    } else if (fieldName === 'image') {
      // Handle image-specific form submission if necessary
      console.log(data.image);
      editProcedure(procedureId, { image: data.image[0] || "" })
        .then(() => {
          setSnackbarMessage('Procedure image updated successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          handleClose();
        })
        .catch(() => {
          setSnackbarMessage('Failed to update procedure image');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });

      console.log(data); // For debugging purposes
      // You might need to handle image uploads differently
    } else {
      editProcedure(procedureId, data)
        .then(() => {
          setSnackbarMessage('Procedure updated successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          handleClose();
        })
        .catch((err) => {
          setSnackbarMessage('Failed to update procedure');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
    }
  };

  return (
    <div>
      <Button variant="text" onClick={handleClickOpen} startIcon={<Iconify icon="tabler:edit" />}>
        Edit {fieldName}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Edit {fieldName}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fieldName === 'name' && (
              <TextField
                autoFocus
                margin="dense"
                id="procedureName"
                name="name"
                label="Procedure Name"
                {...register('name', { required: 'Procedure Name is required' })}
                fullWidth
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}

            {fieldName === 'description' && (
              <TextField
                margin="dense"
                id="description"
                name="description"
                label="Description"
                multiline
                {...register('description', { required: 'Description is required' })}
                fullWidth
                variant="outlined"
                rows={4}
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            )}

            {fieldName === 'content' && (
              <>
                <Typography variant="subtitle1">{fieldSubHeading}</Typography>
                <Editor
                  tinymceScriptSrc="/tinymce/js/tinymce/tinymce.min.js"
                  initialValue={fieldContent || ''}
                  init={{
                    height: 300,
                    menubar: false,
                    selector: 'textarea',
                    toolbar:
                      'undo redo | blocks | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                    content_style: `
                      body { 
                        margin: 10px; 
                        padding: 10px;
                        line-height: 1.6; 
                        font-family: Arial, sans-serif; 
                      }
                      h1, h2, h3, h4, h5, h6 {
                        margin-top: 0.5em; 
                        margin-bottom: 0.5em;
                      }
                      p {
                        margin: 0 0 1em 0;
                      }
                      ul, ol {
                        margin: 0 0 1em 20px;
                      }
                      code {
                        background: #f4f4f4; 
                        padding: 2px 4px; 
                        border-radius: 4px;
                      }
                    `,
                  }}
                  onEditorChange={(content) => {
                    console.log(content);
                    setValue('content', content);
                  }}
                />
                {errors.content && (
                  <Typography color="error" variant="caption">
                    {errors.content?.message}
                  </Typography>
                )}
              </>
            )}
            {console.log(fieldName, fieldContent)}
            {fieldName === 'image' && (
              <RHFImageUpload
                initialImage={fieldContent.length === 0 ? null: fieldContent} // Provide initial images if any
                fileName={`procedure_image_${new Date().toISOString()}`} // Adjust according to your needs
                folderName={procedureId} // Adjust according to your needs
                displayImages
                maxFiles={1}
                filesRequired={1}
                name="image"
                setValue={setValue}
                width={150}
                height={150}
                label="Upload Image"
                maxHeight={240}
                uploadBox
              />
            )}

            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Save</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
