import {
  Alert,
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form/form-provider';
import { useResponsive } from 'src/hooks/use-responsive';
import { addClinicDoctor, getUserDetails } from 'src/services/clinic.service';
import { RHFTextField } from 'src/components/hook-form';
import RHFImageUpload from 'src/components/hook-form/rhf-image-upload';
import RHFTextFieldWithChips from 'src/components/hook-form/rhf-chip-text-field';
import { useAuthContext } from 'src/auth/hooks';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AddClinicDoctorView() {
  const navigate = useNavigate();
  const lgUp = useResponsive('up', 'lg');
  const { user } = useAuthContext();
  const [searchParams] = useSearchParams();
  const [userDetails, setUserDetails] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const user_id = searchParams.get('user_id');

  useEffect(() => {
    if (user_id !== 'null') {
      getUserDetails(user_id).then((data) => {
        console.log(data);
        setUserDetails(data);
      });
    }
  }, [user_id]);

  const fileName = `doctor_profile.${new Date().toISOString()}`;
  const folderName = `images/users`;

  const doctorSchema = Yup.object({
    user_details: Yup.object({
      first_name: Yup.string().required('First name is required'),
      last_name: Yup.string(),
      telephone_number: Yup.string().required('Telephone number is required'),
      password: Yup.string(),
      email: Yup.string().email().required('Email is required'),
    }),
    profile_photo: Yup.string().url(),
    registration_number: Yup.string().required('Registration number is required'),
    services_provided: Yup.array().of(Yup.string()),
    specalization: Yup.array().of(Yup.string()),
    experience: Yup.number(),
    qualification: Yup.string(),
    super_specalization: Yup.array().of(Yup.string()),
    fellowship: Yup.array().of(Yup.string()),
    awards: Yup.array().of(Yup.string()),
    certificates: Yup.array().of(Yup.string()),
  });

  const defaultValues = {
    user_details: {
      first_name: '',
      last_name: '',
      telephone_number: '',
      password: '',
      email: '',
    },
    profile_photo: '',
    registration_number: '',
    services_provided: [],
    specialization: [],
    experience: 0,
    qualification: '',
    super_specialization: [],
    fellowship: [],
    awards: [],
    certificates: [],
    education: [],
  };

  const { control, reset, handleSubmit, setValue } = useForm({
    resolver: yupResolver(doctorSchema),
    defaultValues,
  });

  useEffect(() => {
    if (userDetails) {
      reset({
        user_details: {
          first_name: userDetails.first_name || '',
          last_name: userDetails.last_name || '',
          telephone_number: userDetails.telephone_number || '',
          password: userDetails.password || '',
          email: userDetails.email || '',
        },
        profile_photo: '',
        registration_number: '',
        services_provided: [],
        specialization: [],
        experience: 0,
        qualification: '',
        super_specialization: [],
        fellowship: [],
        awards: [],
        certificates: [],
        education: [],
      });
    }
  }, [userDetails, reset]);

  const onSubmit = (data) => {
    const finalData = {
      user_details: {
        first_name: data.user_details.first_name,
        last_name: data.user_details.last_name,
        telephone_number: data.user_details.telephone_number,
        password: data.user_details.password,
        email: data.user_details.email,
        roles: userDetails ? new Array(...new Set([...userDetails.roles, 'doctor'])) : ['doctor'],
      },
      user_id: user_id !== 'null' && user_id !== null ? user_id : null,
      doctor_details: {
        profile_photo: data.profile_photo,
        registration_number: data.registration_number,
        services_provided: data.services_provided,
        specialization: data.specialization,
        experience: data.experience,
        qualification: data.qualification,
        super_specialization: data.super_specialization,
        fellowship: data.fellowship,
        awards: data.awards,
        certificates: data.certificates,
        education: data.education,
      },
    };
    addClinicDoctor(user.clinic_id, finalData)
      .then(() => {
        console.log('Doctor added successfully');
        reset();
        navigate(`/clinic-dashboard/doctors`);
      })
      .catch((error) => {
        console.error('Error adding doctor', error);
        setErrorMsg(typeof error === 'string' ? error : error.message);
      });
    console.log(finalData);
  };

  return (
    <Container
      sx={{
        background: { lg: 'white' },
        margin: { lg: 4 },
        marginX: 'auto',
        padding: { lg: 3 },
        width: '90%',
        maxWidth: 840,
        borderRadius: 4,
      }}
    >
      <Stack direction="row" gap="14px" alignItems="center" sx={{ marginBottom: '14px' }}>
        {lgUp && (
          <IconButton
            aria-label="back-button"
            size="large"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Iconify icon="tabler:chevron-left" />
          </IconButton>
        )}
        <Typography variant="h4" sx={{ width: '100%' }} textAlign={{ xs: 'center', lg: 'left' }}>
          Add Doctor Details
        </Typography>
      </Stack>
      <FormProvider onSubmit={handleSubmit(onSubmit)} methods={{ control, handleSubmit }}>
        <Grid container rowSpacing={{ xs: '14px', lg: '24px' }} columnSpacing={{ lg: '24px' }}>
          <Grid item xs={12}>
            {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFTextField
              name="user_details.first_name"
              label="First Name"
              margin="dense"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFTextField
              name="user_details.last_name"
              label="Last Name"
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFTextField
              name="user_details.email"
              label="Email Address"
              margin="dense"
              variant="outlined"
            />
          </Grid>
          {user_id === 'null' && (
            <Grid item xs={12} lg={6}>
              <RHFTextField
                name="user_details.password"
                label="Password"
                margin="dense"
                variant="outlined"
              />
            </Grid>
          )}
          <Grid item xs={12} lg={6}>
            <RHFTextField
              name="user_details.telephone_number"
              label="Telephone Number"
              margin="dense"
              variant="outlined"
              required
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <RHFTextField
              name="registration_number"
              label="Registration Number"
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFTextField
              name="experience"
              label="Experience"
              margin="dense"
              variant="outlined"
              type="number"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFTextField
              name="qualifications"
              label="Qualifications"
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFTextFieldWithChips
              name="services_provided"
              label="Services Provided"
              margin="dense"
              variant="outlined"
              setValue={setValue}
              multiple
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFTextFieldWithChips
              name="specialization"
              label="Specialization"
              margin="dense"
              variant="outlined"
              setValue={setValue}
              multiple
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFTextFieldWithChips
              name="fellowship"
              label="Fellowship"
              margin="dense"
              variant="outlined"
              setValue={setValue}
              multiple
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFTextFieldWithChips
              name="awards"
              label="Awards"
              margin="dense"
              variant="outlined"
              setValue={setValue}
              multiple
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFTextFieldWithChips
              name="certificates"
              label="Certificates"
              margin="dense"
              variant="outlined"
              setValue={setValue}
              multiple
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFTextFieldWithChips
              name="educations"
              label="Education Details"
              margin="dense"
              variant="outlined"
              setValue={setValue}
              multiple
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFImageUpload
              name="profile_photo"
              fileName={fileName}
              folderName={folderName}
              label="Upload Profile Photo"
              setValue={setValue}
              displayImages
              maxFiles={1}
              width={300}
              height={300}
              maxHeight={300}
            />
          </Grid>
          <Grid container item alignItems="end" xs={12} justifyContent="flex-end">
            <Button
              variant="contained"
              sx={{
                width: 1,
                boxShadow: 10,
                fontSize: { lg: '16px', xs: '12px' },
                background: '#D92D7F',
                ':hover': { background: '#D92D7F' },
              }}
              type="submit"
            >
              Add Doctor
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  );
}
