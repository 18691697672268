import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
import { urlMap } from 'src/utils/urls';
//
import { AuthContext } from './auth-context';
import { isValidToken, setToken,getToken,setUser,getUser } from './utils';


// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------


export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = getToken();
      // console.log("accessToken from local storage is ",accessToken);

      if (accessToken && isValidToken(accessToken)) {
        setToken(accessToken);
        const  user  = getUser();
        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (loginDetails, password) => {
    const data = {
      loginDetails,
      password,
    };

    const response = await axios.post(urlMap.auth.login, data);
    // console.log(response.data); 
    const { user,access_token } = response.data;
    // console.log('user',user);
    // console.log('access_token',access_token); 
    setToken(access_token);
    if (user && user.name) {
      user.displayName = user.name;
    }
    setUser(user);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

// LOGIN
  const clinicLogin = useCallback(async (loginDetails, password) => {
    const data = {
      loginDetails,
      password,
    };

    const response = await axios.post(urlMap.auth.clinic_login, data);
    // console.log(response.data); 
    const { user,access_token } = response.data;
    // console.log('user',user);
    // console.log('access_token',access_token); 
    setToken(access_token);
    if (user && user.name) {
      user.displayName = user.name;
    }
    setUser(user);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);
  

// LOGIN
  const doctorLogin = useCallback(async (loginDetails, password) => {
    const data = {
      loginDetails,
      password,
    };

    const response = await axios.post(urlMap.auth.doctor_login, data);
    // console.log(response.data); 
    const { user,access_token } = response.data;
    // console.log('user',user);
    // console.log('access_token',access_token); 
    setToken(access_token);
    if (user && user.name) {
      user.displayName = user.name;
    }
    setUser(user);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);


  // LOGOUT
  const logout = useCallback(async () => {
    setToken(null);
    setUser(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      clinicLogin,
      doctorLogin,
      logout,
    }),
    [login, clinicLogin,doctorLogin,logout, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
