import { Container, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Parser } from 'html-to-react';
import DOMPurify from 'dompurify'; // If sanitizing
import { useParams } from 'src/routes/hooks';
import { getProcedureDetails } from 'src/services/procedures.service';
import { LoadingScreen } from 'src/components/loading-screen';
import EditProcedurePopup from './edit-procedure-popup';

const DefaultProcedureSubContents = [
  'Procedure overview',
  'Ideal candidates',
  'Procedure details',
  'Costs and insurance',
  'Benefits',
  'Risks and complications',
  'Patient experiences',
  'Expert opinions',
  'Regulatory and ethical considerations',
  'Technological advancements',
  'Alternative treatment',
  'Disclaimer',
  'Conclusion',
  'FAQs',
];

const HtmlContent = ({ html }) => {
  const parser = new Parser();
  const cleanHtml = DOMPurify.sanitize(html); // Sanitize if necessary
  const reactElement = parser.parse(cleanHtml);

  return <div style={{ marginTop: '10px', lineHeight: '1.6', color: '#333' }}>{reactElement}</div>;
};

export default function EditProcedureView() {
  const [procedureDetails, setProcedureDetails] = useState(null);
  const [missingSubContents, setMissingSubContents] = useState([]);
  const params = useParams();

  useEffect(() => {
    getProcedureDetails(params.procedureId)
      .then((details) => {
        // Sanitize HTML content
        details.content.forEach((section) => {
          section.description = section.description.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
        });

        setProcedureDetails(details);

        // Extract the headings from the content array
        const existingHeadings = details.content.map((item) => item.heading);

        // Identify missing sub-contents
        const missingContents = DefaultProcedureSubContents.filter(
          (subContent) => !existingHeadings.includes(subContent)
        );
        setMissingSubContents(missingContents);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.procedureId]);

  if (!procedureDetails) {
    return <LoadingScreen />;
  }

  return (
    <Container>
      <Typography variant="h4" marginBottom={3}>
        Edit Procedure
      </Typography>
      <Stack gap={3}>
        {/* Procedure Name */}
        <Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Procedure Name</Typography>
            <EditProcedurePopup
              fieldName="name"
              fieldContent={procedureDetails.name}
              procedureId={params.procedureId}
            />
          </Stack>
          <Typography>{procedureDetails.name}</Typography>
        </Stack>

        {/* Procedure Description */}
        <Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Procedure Description</Typography>
            <EditProcedurePopup
              fieldName="description"
              fieldContent={procedureDetails.description}
              procedureId={params.procedureId}
            />
          </Stack>
          <Typography>{procedureDetails.description}</Typography>
        </Stack>

        <Typography variant="h6">Procedure Content</Typography>

        {/* Procedure Content Sections */}
        {procedureDetails.content.map((section, index) => (
          <Stack key={index} gap={1}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h5">{section.heading}</Typography>
              <EditProcedurePopup
                fieldName="content"
                fieldContent={section.description}
                fieldSubHeading={section.heading}
                procedureId={params.procedureId}
              />
            </Stack>
            {/* Render HTML content using div and dangerouslySetInnerHTML */}
            <HtmlContent html={section.description} />
          </Stack>
        ))}

        {/* Render missing sub-contents separately */}
        {missingSubContents.length > 0 && (
          <Stack>
            {missingSubContents.map((subContent, index) => (
              <Stack key={index} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h5">{subContent}</Typography>
                <EditProcedurePopup
                  fieldName="content"
                  fieldSubHeading={subContent}
                  procedureId={params.procedureId}
                />
              </Stack>
            ))}
          </Stack>
        )}

        <Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Procedure Image</Typography>
            <EditProcedurePopup
              fieldName="image"
              fieldContent={procedureDetails.image}
              procedureId={params.procedureId}
            />
          </Stack>
          {console.log(procedureDetails.image.length)}
          {procedureDetails.image.length === 0 ? (
            <p>Upload Image</p>
          ) : (
            <img src={procedureDetails.image} alt="procedure cover" />
          )}
        </Stack>
      </Stack>
    </Container>
  );
}
